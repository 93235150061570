<template>
    <navbar position="fixed"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            menu-classes="ml-auto">
        <template slot-scope="{toggle, isToggled}">
                <router-link class="navbar-brand" to="/">
                    <!-- <img v-if="props.transparent === true" src="img/bioxsys_negative.svg" height="60px" alt="" class="" /> -->
                    <img src="img/bioxsys.svg" height="60px" alt="" class="" />
                </router-link>
        </template>
        <template slot="navbar-menu">

        <!-- <li class="nav-item">
            <router-link
            class="router-link-item nav-link btn btn-primary"
            to="/Welcome#novinky"
            ><b>Novinky</b>
            </router-link>
        </li> -->

            <!-- <li class="nav-item">
                <router-link class="router-link-item nav-link btn btn-covid" to="/covid19"><i class="fas fa-exclamation-circle" style="color:red"></i> SARS-CoV-2 </router-link>
            </li> -->

            <drop-down tag="li" title="Aplikace" icon="fas fa-desktop" class="nav-item">
                <nav-link to="/genovesa"> Genovesa </nav-link>
            </drop-down>

            <drop-down tag="li" title="Kurzy" icon="fas fa-graduation-cap" class="nav-item">
                <nav-link to="/kurzy"> Přehled kurzů </nav-link>
                <!-- <nav-link to="/dotace"> Jak ziskat dotaci od MPSV </nav-link> -->
            </drop-down>

            <drop-down tag="li" title="Služby" icon="fas fa-cogs" class="nav-item">
                <nav-link to="/biosupport"> Bioinformatika </nav-link>
                <nav-link to="/itsupport"> Vývoj Software </nav-link>
<!--                 <nav-link to="/vyvoj"> Infrastruktura pro bioinformatiku </nav-link>
 -->            </drop-down>

            <drop-down tag="li" title="FAQ" icon="fas fa-question" class="nav-item">
                <nav-link class="dropdown-item" to="/faq-bioinformatika"> Bioinformatika </nav-link>
                <nav-link class="dropdown-item" to="/faq-genovesa"> Genovesa </nav-link>
                <!-- <nav-link class="dropdown-item" to="/faq-vyvoj-aplikaci"> Vývoj aplikací </nav-link> -->
                <!-- <nav-link class="dropdown-item" to="/faq-ochrana-dat"> Ochrana dat </nav-link> -->
                <nav-link class="dropdown-item" to="/faq-kurzy"> Kurzy </nav-link>
             </drop-down>

            <li class="nav-item">
                <router-link class="router-link-item nav-link" to="/onas"><i class="fas fa-users"></i> O nás </router-link>
            </li>

            <li class="nav-item">
                <router-link class="router-link-item nav-link" to="/kontaktujte"><i class="fas fa-address-card"></i> Kontaktujte nás </router-link>
            </li>

        </template>
    </navbar>
</template>

<script>
  import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'
  export default {
    name: 'main-navbar',
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    },
    components: {
      DropDown,
      Navbar,
      NavbarToggleButton,
      NavLink,
      [Popover.name]: Popover
    }
  }
</script>

<style scoped>
.navbar {
    padding: 0px !important;

}

.text_center {
    text-align: center !important;

}

.btn-covid {
  color: #0004ff;
  background-color: #fff;
}
</style>
