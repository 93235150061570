import Vue from "vue";
import Router from "vue-router";
import Welcome from "./pages/Welcome.vue";
import Kontaktujte from "./pages/Kontaktujte.vue";
import onas from "./pages/onas.vue";

import Software from "./pages/Software.vue";
import Bioinformatika from "./pages/Bioinformatika.vue";

import a_genovesa from "./pages/a_genovesa.vue";
import a_covid from "./pages/a_covid.vue";
import kurzy from "./pages/kurzy.vue";

import s_biosup from "./pages/s_biosup.vue";
import s_itsup from "./pages/s_itsup.vue";
import x_covid19 from "./pages/x_covid19.vue";

import f_bioinformatika from "./pages/f_bioinformatika.vue";
import f_it from "./pages/f_it.vue";
import f_genovesa from "./pages/f_genovesa.vue";
import f_ochrana_dat from "./pages/f_ochrana_dat.vue";
import f_kurzy from "./pages/f_kurzy.vue";
import f_skoleni from "./pages/f_skoleni.vue";

import Components from "./pages/Components.vue";
import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import About from "./pages/example-pages/AboutUs.vue";
import BlogPost from "./pages/example-pages/BlogPost.vue";
import BlogPosts from "./pages/example-pages/BlogPosts.vue";
import ContactUs from "./pages/example-pages/ContactUs.vue";
import LandingPage from "./pages/example-pages/LandingPage.vue";
import LoginPage from "./pages/example-pages/LoginPage.vue";
import PricingPage from "./pages/example-pages/PricingPage.vue";
import EcommercePage from "./pages/example-pages/EcommercePage.vue";
import ProductPage from "./pages/example-pages/ProductPage.vue";
import ProfilePage from "./pages/example-pages/ProfilePage.vue";
import SignUpPage from "./pages/example-pages/SignUpPage.vue";
import Sections from "./pages/Sections.vue";

Vue.use(Router);

const routes = [
    {
        path: "/",
        redirect: "/welcome"
    },

    {
        path: "/welcome",
        name: "welcome",
        components: { default: Welcome, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/covid19",
        name: "x_covid19",
        components: { default: x_covid19, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/genovesa",
        name: "a_genovesa",
        components: { default: a_genovesa, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },
    {
        path: "/covid",
        name: "a_covid",
        components: { default: a_covid, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },
    {
        path: "/faq-bioinformatika",
        name: "f_bioinformatika",
        components: { default: f_bioinformatika, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },
    {
        path: "/faq-it",
        name: "f_it",
        components: { default: f_it, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },
    {
        path: "/faq-genovesa",
        name: "f_genovesa",
        components: { default: f_genovesa, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/faq-ochrana-dat",
        name: "f_ochrana_dat",
        components: { default: f_ochrana_dat, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/faq-kurzy",
        name: "f_kurzy",
        components: { default: f_kurzy, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/faq-skoleni",
        name: "f_skoleni",
        components: { default: f_skoleni, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/kurzy",
        name: "kurzy",
        components: { default: kurzy, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/kontaktujte",
        name: "kontaktujte",
        components: { default: Kontaktujte, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },


    {
        path: "/biosupport",
        name: "s_biosup",
        components: { default: s_biosup, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/itsupport",
        name: "s_itsup",
        components: { default: s_itsup, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/vyvoj-aplikaci",
        name: "s_vyvoj_app",
        components: { default: s_itsup, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/onas",
        name: "onas",
        components: { default: onas, header: MainNavbar, footer: MainFooter },
        props: {
            footer: { backgroundColor: 'gray' },
            header: { colorOnScroll: 0 }
        }
    },

    {
        path: "/login",
        name: "login",
        components: { default: LoginPage, header: MainNavbar },
        props: { header: { colorOnScroll: 450 } }
    },

    {
        path: "/profile",
        name: "profile",
        components: { default: ProfilePage, header: MainNavbar, footer: MainFooter },
        props: { header: { colorOnScroll: 450 } }
    },

    {
        path: "/signup",
        name: "signup",
        components: { default: SignUpPage, header: MainNavbar },
        props: { header: { colorOnScroll: 450 } }
    },

]



    // scrollBehavior: (to, from, savedPosition) => {
    //     if (to.hash) {
    //         return { selector: to.hash }
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // },


export default routes;
